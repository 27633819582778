import React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { device } from "../device"
import Img from "gatsby-image/withIEPolyfill"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NewsPage = ({ data }) => {
  const options = {}
  const newsContents = data.allContentfulNews.edges

  return (
    <Layout>
      <SEO title="news" />
      <NewsListContainer>
        {newsContents.map((newsContent, index) => (
          <NewsSection key={index}>
            <NewsThumb>
              {newsContent.node.thumbImage && (
                <Img
                  fluid={newsContent.node.thumbImage.fluid}
                  objectFit="contain"
                />
              )}
            </NewsThumb>
            <NewsBody>
              <D>{newsContent.node.publishedAt}</D>
              <Title>{newsContent.node.title}</Title>
              <Content>
                {renderRichText(newsContent.node.content, options)}
              </Content>
            </NewsBody>
          </NewsSection>
        ))}
      </NewsListContainer>
    </Layout>
  )
}

const NewsListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const NewsSection = styled.section`
  padding: 32px 0;
  border-bottom: 1px solid hsla(90, 80%, 40%, 0.15);
  /* min-height: calc(100vh - 80px); */
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  &:first-child {
    padding-top: 0;
  }

  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`

const NewsBody = styled.div`
  flex-shrink: 1;
  max-width: 70%;
  padding-bottom: 32px;

  @media ${device.mobile} {
    max-width: 100%;
  }
`

const NewsThumb = styled.div`
  flex-basis: 15%;
  img {
    height: 400px;
  }

  @media ${device.mobile} {
    max-width: 45%;
    img {
      height: 200px;
    }
  }
`

const Title = styled.h2`
  font-size: 0.95rem;
  font-weight: bold;
  margin-bottom: 24px;
`

const D = styled.p`
  font-size: 0.8rem;
  font-family: ${props => props.theme.fontFamily.roboto};
  margin-bottom: 16px;
  margin-top: 0;
`

const Tags = styled.div`
  display: flex;
  flex-direction: row;
`
const Tag = styled.span`
  font-family: ${props => props.theme.fontFamily.roboto};
  font-size: 0.8rem;
  color: #212121;
  padding: 8px 0;
`

const Content = styled.div`
  font-size: 0.85rem;
  a {
    font-family: ${props => props.theme.fontFamily.roboto};
    color: hsla(90, 80%, 20%, 0.5);
  }
`

export const query = graphql`
  query {
    allContentfulNews {
      edges {
        node {
          title
          publishedAt(formatString: "YYYY.MM.DD dd")
          content {
            raw
          }
          tag
          thumbImage {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default NewsPage
